import { Controller } from '@hotwired/stimulus'
import Highcharts from 'highcharts';

export default class extends Controller {
  static targets = ['container']
  static values = { options: Object, forReport: Boolean }

  connect() {
    const options = this.customizeLabel(this.optionsValue, this.forReportValue)
    Highcharts.chart(this.containerTarget, options)
  }

  customizeLabel(options, forReport) {
    if (options.chart.type != 'pie' && !forReport) { return options; }
    options.legend.labelFormatter = function () {
      const thousandsSep = options.lang.thousandsSep || ',';
      const decimalPoint = options.lang.decimalPoint || '.';
      const formattedY = Highcharts.numberFormat(this.y, 2, decimalPoint, thousandsSep);
      const formattedQuantity = Highcharts.numberFormat(this.options.quantity, 2, decimalPoint, thousandsSep);

      return `${this.name.toUpperCase()} ${formattedQuantity} (${formattedY}%)`;
    };
    
    return options;
  }
}
